.wrapper {
    background-color: var(--color--milk-bg);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15.1rem;

    padding: 6rem 8rem 13.1rem 8rem;
}

.desc{
    margin-top: 7.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.desc h1{
    font-size: 7.2rem;
}

.desc p{
    max-width: 53.1rem;
    font-weight: 300;
}

.desc a{
    display: block;
    align-self: flex-start;
}

.desc a, .desc a:link, .desc a:visited, .desc a:active{
    font-size: 2.4rem;
    font-weight: 300;
    color: var(--color--black);
    text-decoration: none;
}

.desc a:hover{
    text-decoration: underline;
}

.desc img{
    max-width: 51.8rem;
    max-height: 26.2rem;
    object-fit: cover;

    margin-top: 5.3rem;
}

.form{
    padding:3.6rem 4rem;
    border-radius: 2.5rem;
    background-color: var(--color--white);
    box-shadow: 0 .4rem 1.8rem .4rem rgba(0,0,0,.25);

    position: relative;
}

.success{
    position: relative;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    background-color: var(--color--primary);
    color: var(--color--light-yellow);
    padding: 1.2rem 2.4rem;
    border-radius: 1rem;
}

.inputs{
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.names input{
    padding: 1.8rem 2.5rem;
    font-size: 2rem;
    border: .1rem solid var(--color--grey);
    border-radius: 5rem;

    width: 100%;
}

.textarea{
    height: 21.5rem;
    padding: 3rem 2.5rem;
    font-size: 2rem;
    border: .1rem solid var(--color--grey);
    border-radius: 2.5rem;
    width: 100%;
}

.form input::placeholder, .textarea::placeholder{
    color: var(--color--grey);
    font-weight: lighter;
}

.form-header{
    margin-bottom: 5.5rem;
}

.form-header h1{
    font-weight: 500;
    margin-bottom: 1rem;
}

.names{
    display: flex;
    gap: 1.8rem;
}

.email{
    font-size: 2rem;
    border: .1rem solid var(--color--grey);
    border-radius: 5rem;

    
    display: grid;
    align-items: center;
    grid-template-columns: 5.7rem 1fr;
    gap: .4rem;

    overflow: hidden;
}

.icon-wrapper{
    padding-left:2.5rem;
}

.icon{
    width: 2.8rem;
    height: 2.8rem;
    stroke: var(--color--grey);
}

.email input{
    border: none;
    padding: 1.8rem 2.5rem 1.8rem .4rem ;
}

.email input:focus{
    outline: none;
}

.phone-class{
    width: 100%;
    /* background-color: red; */
    border-radius: 2.5rem;
    overflow: hidden;

     padding-top: 1.8rem;
     padding-bottom: 1.8rem;
}

.phone-input{
     font-size: 2rem;
     width: 100%;
}

.submit-btn{
    padding-top: 1.8rem;
    padding-bottom: 1.9rem;
    font-size: 2rem;
}

.message{
    font-size: 2rem;
    max-width: 41.4rem;
    text-align: center;
    margin: 0 auto;
}

.message strong{
    font-size: 2rem;
}


@media (max-width: 78.875em) {
    .wrapper{
        gap: 10rem;
        padding-left: 4rem;
        padding-right: 4rem;
    } 
}


@media (max-width: 68.75em) {
    .wrapper{
        gap: 8rem;
        grid-template-columns: 40% 1fr;
    } 

    .desc h1{
        font-size: 5.6rem;
    }
}


@media (max-width: 50em) {
    .wrapper{
        display: flex;
        flex-direction: column;
    } 
}


@media (max-width: 25em) {
    .form{
        padding-right:  1rem;
        padding-left:  1rem;
    }

    .names{
        flex-direction: column;
        gap: 2rem;
    }

    .names input, .textarea, .email{
        font-size: 1.4rem;
    }

    .textarea{
        padding: 2rem 1.6rem;
    }

    .submit-btn{
        font-size: 1.6rem;
    }

    .message, .message strong{
        font-size: 1.6rem;
    }
}


@media (max-width: 25em) {
    .wrapper{
        padding-top: 0rem;
        padding-left: 1rem;
        padding-right: 1rem;
    } 

   .desc h1{
        text-align: center;
        font-size: 3.2rem;
    }

   .desc p{
        font-size: 1.6rem;
        text-align: center;
    }

    .desc a:link{
        font-size: 1.6rem;
    }
}