.wrapper {
    height: 82svh;
    position: relative;
}

.hero-img{
    height: 100%;
    width: 100%;

    object-fit:cover;
}

.linear-film{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-image: linear-gradient(to right, rgba(25, 34, 69,100%), rgba(62, 84, 171, 0%));
}

.text-wrapper {
    padding: 3.2rem 8rem;

    position: absolute;
    top: 20.6rem;
    left: 10rem;

    z-index: 120;
}

.heading{
    margin-top: .8rem;
    font-size: 4.8rem;
    font-weight: 600;
    width: 43.8rem;
    color: var(--color--white);
}

.text{
    margin-top: .8rem;
    width: 58.8rem;
    font-size: 2rem;
    font-weight: 200;
     color: var(--color--white);
}

.btn-wrapper{
    margin-top: 4rem;
    display: flex;
    gap: 2.4rem;
    align-items: center;
}

.icon-wrapper{
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: var(--color--primary);

    display: flex;
    align-items: center;
    justify-content: center;
}

.icon{
    width: 3.4rem;
    stroke: var(--color--white);
}

.btn-wrapper span{
    color: var(--color--primary);
    font-size: 2.4rem;
    font-weight: 500;
}


@media (max-width: 50em) {
    .text-wrapper {
        top: 15rem;
        left: 2rem;
    }

    .text{
        font-size: 1.6rem;
        font-weight: 400;
        color: var(--color--white);
    }
}


@media (max-width: 42.5em) {
    .text-wrapper {
        padding-right: 0;
        margin: 0;
    }
}

@media (max-width: 38.125em) {
    .text-wrapper {
        text-align: center;
        width: 40rem;
        padding: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    .text{
        width: 40rem;
        padding: 0;
        font-size: 1.6rem;
    }

    .heading{
        margin-top: .8rem;
        font-size: 3.2rem;
        font-weight: 600;
        width: auto;
        color: var(--color--white);
    }

    .btn-wrapper{
        justify-content: center;
    }

    .icon-wrapper{
        width: 3.2rem;
        height: 3.2rem;
    }

    .icon{
        width: 2.4rem;
    }
}


@media (max-width: 22.5em) {
    .wrapper{
        height: 80svh;
    }

    .text-wrapper{
        width: 30rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    .text{
        width: 30rem;
    }

    .btn-wrapper{
        gap: 1rem;
    }

    .btn-wrapper span{
        font-size: 1.2rem;
        font-weight: bold;
        color: var(--color--white);
    }

    .icon-wrapper{
        width: 2.4rem;
        height: 2.4rem;
    }

    .icon{
        width: 2rem;
    }
} 

