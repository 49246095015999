.header {
    height: 18svh;
    margin: 0 auto;
    margin: 0 8rem;

    display: flex;
    align-items: center; 
    justify-content: space-between;
}

.nav {
    display: flex;
    gap: 2.8rem;
}

.logo{
    width: 30rem;
    height: 148rem;
    object-fit: contain;
}

.nav a:link, .nav a:visited {
    text-decoration: none;
    color: var(--color--black);
    font-size: 2rem;
    font-weight: normal;
} 

.nav a:hover{
    color: var(--color--primary);
}

.bars{
    width: 3.2rem;
    height: 3.2rem;

    cursor: pointer;

     display: none;
    visibility: hidden;
}

.mobile-view{
    width: 100%;
    height: 100svh;

    background-color: green;

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: center;
    gap: 5.6rem;

     display:none;
        visibility: hidden;
}

.close-btn{
    position: absolute;

    top: 5rem;
    right: 5rem;

    width: 3.2rem;
    height: 3.2rem;

    stroke: var(--color--white);

    cursor: pointer;
}

.mobile-view a:link, .mobile-view a:visited{
    color: var(--color--white);
    font-size: 2.4rem;
    text-decoration: none;
}

.mobile-view a:hover{
        color: var(--color--primary);
}

@media (max-width:72.5em) {
    
    .nav a:link, .nav a:visited {
        font-size: 1.6rem;
    }    
    
    .logo{
        width: 25rem;
        height: 120rem;
    }
}

@media (max-width:60em) {
    
    .nav a:link, .nav a:visited {
        font-size: 1.4rem;
    }    
    
    .logo{
        width: 20rem;
        height: 96rem;
    }
}


@media (max-width:50em) {
    .header {
        margin: 0 1.2rem;
    }

    .nav{
        display: none;
        visibility: hidden;
    }

    .mobile-view {
        display: flex;
        visibility: visible;
    }

    .bars {
        display: block;
        visibility: visible;
    }
}


@media (max-width:37.5em) {
    .header {
        margin: 0 1.2rem;
    }
}

@media (max-width: 22.5em) {
    .header {
        height: 10svh;
    }

    .logo{
        width: 10rem;
    }
}