.section{
    padding: 4rem 10rem 5.4rem 10rem;
    background-color: var(--color--violet);
    color: var(--color--white);
}

.section h3{
    color: var(--color--white);
    margin-bottom: 3rem;
}

.sec-desc{
    margin-bottom: 2rem;
}

.ul-btn{
    display: flex;
    justify-content: space-between;
    margin-right: 8rem;
    margin-bottom: 4.5rem;
}

.ul {
    margin-left: 4rem;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
}

.ul li{
    font-size: 2.4rem;
}

.btn{
    align-self: flex-end;
    border-color: var(--color--light-yellow);
    color: var(--color--light-yellow);
    font-size: 2.4rem;
}

.btn:hover{
    border-color: var(--color--dark-yellow);
}


@media (max-width: 67.1875em) {
    .ul li{
        font-size: 1.8rem;
    }

    .btn{
        font-size: 1.8rem;
    }
}

@media (max-width: 50em) {
    .section{
        padding-right: 5.6rem;
        padding-left: 5.6rem;
    }

    .ul-btn{
        display: block;
    }

    .btn{
        margin-top: 2.8rem;
        padding: 1rem 2rem;
    }
}



@media (max-width: 50em) {
    .section{
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

@media (max-width:17.1875rem) {
    .sec-desc, .ul li{
        font-size: 1.6rem;   
    }

    .ul-btn{
        margin-right: 2rem;
    }

    .btn{
        font-size: 1.2rem;
    }
}
