.wrapper {
    padding: 8rem;
}

.heading{
    position: absolute;
}

.heading span{
    font-size: inherit;
    font-weight: bold;
}

.violet-text{
    color: var(--color--violet);
}

.underline{
    width: 18rem;
    height: 1.2rem;
    background-color: #EFECEC;

    position: relative;
    top: 2.4rem;
    left: 0;

    z-index: -1;
}

.form {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;

    position: relative;
}


.success{
    position: relative;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    background-color: var(--color--primary);
    color: var(--color--light-yellow);
    padding: 1.2rem 2.4rem;
    border-radius: 1rem;
}

.col--2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.5rem;
}

.input-wrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.input-wrapper label{
    font-size: 2rem;
    font-weight: normal;
}

.input-wrapper input, .input-wrapper textarea{
    font-size: 2rem;
    padding: 2rem 3rem;
    border-radius: 1rem;
    border: .1rem solid var(--color--violet);
}

.input-wrapper textarea{
    height: 20rem;
}

.phone-input{
    width: 100%;
}

.checkbox-wrapper{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.checkbox-wrapper label{
    font-size: 2rem;
}

.checkbox-wrapper input{
    width: 2.4rem;
    height: 2.4rem;
}

.btn--submit{
    font-size: 2rem;
    font-weight: 500;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 2rem;
    border: none;
    background-color: var(--color--violet);
    color: var(--color--white);
    cursor: pointer;
}


@media (max-width:70.5em) {
    .wrapper{
        margin-top: 5rem;
    }
}


@media (max-width:40.625em) {
    .wrapper{
        padding: 4rem;
    }

    .col--2{
        display: flex;
        flex-direction: column;
        gap: 4rem;
    }
}


@media (max-width:25em) {
    .wrapper{
        padding: 1rem;

    }

    .heading span{
        font-size: 1.8rem;
    }

    .underline{
        width: 10rem;
        height: .6rem;
        top: 3rem;
    }

    .form label{
        font-size: 1.8rem;
    }

    .input-wrapper input, .input-wrapper textarea{
        font-size: 1.4rem;
        padding: 1rem 2rem;
        border-radius: 1rem;
        border: .1rem solid var(--color--violet);
    }

    .btn--submit{
        font-size: 1.6rem;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
    }
}