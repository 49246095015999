.wrapper {
    position: relative;
}

.wrapper, .bg, .linear-box{
    height: 73.3rem;
    width: 100%;
}

.bg, .linear-box{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 73.3rem;
}

.bg{
    z-index: -15;
}

.linear-box{
    z-index: -10;
    background-image: linear-gradient(#D8D4D5, rgba(222, 118, 85, 0.4));
}

.heading{
    position: absolute;
   
    right: 7.8rem;
    bottom: -12.6rem;

    width: 68.2rem;
    height: 25.2rem;
    background-color: var(--color--violet);

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 2.5rem;
}

.heading h1{
    font-size: 7.2rem;
    font-weight: bold;
    color: var(--color--white);
}


@media (max-width: 50em) {
    .wrapper, .bg, .linear-box{
        height: 73.3svh;
    }

    .heading{
        left: 50%;
        bottom: -25%;

        transform: translate(-50%, -50%);
        max-width: 40rem;
        max-height: 15rem;
    }

    .heading h1{
        font-size: 3.2rem;
    }
}

@media (max-width: 25em) {
    .heading h1{
        font-size: 2rem;
    }

    .heading{
        max-width: 20rem;
        max-height: 10rem;

        bottom: -15%;
    }
}