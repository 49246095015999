.wrapper {
    position: relative;
    height: 48rem;
    margin-bottom: 12rem;
}

.img, .linear{
    width: 100%;
    height: 48rem;
    position: absolute;
}

.img{
    object-fit: cover;
}

.linear{
    background-color: rgba(25, 34, 69,80%);
}

.text{
    position: absolute;
    left: 16rem;
    top: 17.1rem;
    padding: 9.8rem 10.5rem;
    
    background-color: var(--color--primary);
    color: var(--color--white);

}

.text span{
    display: block;
    font-size: 6.4rem;
}

@media (max-width:50em) {
    .text{
        padding: 5.6rem;
    }

    .text span{
        font-size: 2.4rem;
    }
}

@media (max-width:25em) {
    .text{
        padding: 5.6rem;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .text span{
        font-size: 2rem;
    }
}