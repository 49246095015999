.wrapper{
    padding: 8rem 10rem 0rem 10rem;
}

.footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;
    margin-bottom: 2.4rem;
}

.logo-socials{
    width: 30rem;
}

.logo-img{
    width: 30rem;
    height: 14.8rem;
    object-fit: contain;
    /* margin-bottom: 1rem; */
}

.socials{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.social{
    object-fit: contain;
    cursor: pointer;
}

.nav {
    display: flex;
    gap: 2.8rem;
}

.nav a:link, .nav a:visited {
    text-decoration: none;
    color: var(--color--black);
    font-size: 1.2rem;
    font-weight: normal;
} 

.nav a:hover{
    color: var(--color--primary);
}

.copyright{
    padding: 1.3rem 4.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 300;
}

.copyright p a, .copyright p a:visited{
    color: var(--color--black);
    font-size: 1.8rem;

}


@media (max-width:72.5em) {
    .logo{
        width: 25rem;
        height: 120rem;
    }
}

@media (max-width:60em) {
    
    .logo{
        width: 20rem;
        height: 96rem;
    }
}


@media (max-width:50em) {
    .footer{
        flex-direction: column;
        gap: 5.6rem;
        
    }
}

@media (max-width:37.5em) {

    .wrapper{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .copyright p{
        font-size: 1.2rem;
    }

    .logo-img{
        margin: 0 auto;
        width: 20rem;
        margin: 0;
        padding: 0;
    }

    .logo-socials{
        width: auto;
    }

    .nav{
        flex-wrap: wrap;
        justify-content: center;
    }

    .social{
        width: 2.4rem;
        height: 2.4rem;
    }

}