.wrapper {
    background-color: var(--color--violet);
    padding: 9.9rem 7.4rem 11.6rem 8rem;
    color: var(--color--white);

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper h2{
    font-size: 6.4rem;
    font-weight: 700;
    max-width: 36.6rem;
    color: var(--color--white);
}

.wrapper p{
    max-width: 80rem;
}

@media (max-width:50em) {
    .wrapper{
        padding: 4rem;
        display: block;
    }

    .wrapper h2{
        font-size: 3.2rem;
        margin-bottom: 2.4rem;
    }

    .wrapper p{
        font-size: 1.8rem;
    }
}

@media (max-width:17.1875em) {
    .wrapper{
        padding: 4rem 1rem;
    }

    .wrapper h2{
        font-size: 2.4rem;
    }

    .wrapper p{
        font-size: 1.6rem;
    }
}