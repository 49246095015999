.wrapper {
    padding: 8rem 10rem 8rem 8rem;
}

.wrapper h3{
    margin-bottom: 6.4rem;
}

.core-values{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 6.4rem;
}

.icon{
    margin-bottom: 2rem;
    height: 9.6rem;
}

.value{
    width: 36.6rem;
}

.value h4{
    margin-bottom: 1.5rem;
}

@media (max-width: 50em) {

    .value{
        width: 30rem;
    }
}

@media (max-width:37.5em) {
    .wrapper h3{
        text-align: center;
    }

     .core-values{
        display: flex;
        flex-direction: column;
        align-items: center;
     }

     .value{
        text-align: center;
     }
}


@media (max-width:25em) {
    .wrapper{
        padding: 4rem;
    }

    .value{
        width: 20rem;
    }
}

@media (max-width:17.1875em) {
    .wrapper{
        padding: 1rem;
    }

    .text{
        font-size: 1.6rem;
    }
}