.wrapper {
    margin-top: 16.6rem;
    padding: 4rem 16.2rem 4rem 8rem;
    background-color: var(--color--milk-bg);
}

.mission h3, .vision h3{
    margin-bottom: 3rem;
}

.mission p, .vision p{
    font-size: 2.4rem;
    font-weight: normal;
}

.mission{
    margin-bottom: 4.7rem;
}

@media (max-width:50em) {
    .wrapper{
        padding: 4rem;
    }

    .mission p, .vision p{
        font-size: 1.8rem;
    }
}

@media (max-width:17.1875em) {
    .wrapper{
        margin: 1rem;
        margin-top: 8rem;
        padding: 2rem;
    }

    .mission p, .vision p{
        font-size: 1.6rem;
    }
}