/* Anowam */

:root {
  /* COLORS */
  --color--primary: #E05E35;
  --color--primary__tint: #b34b2a;
  --color--violet: #192245;
  --color--white: #fff;
  --color--milk-bg: #F7F4F4;
  --color--grey: #B5B5B5;
  --color--black: #000;
  --color--light-yellow: #F2E137;
  --color--dark-yellow: #dfd028;
}

html{
  font-size: 62.5%;
}

*{
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  font-size: 1.4rem;
}

body {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color--black);
  font-size: 1.8rem;

  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}



#map { height: 180px; }

.error-block{
  color: red;
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
}

h1{
  color: var(--color--primary);
  font-size: 4rem;
  font-weight: 800;
}

h2{
  color: var(--color--primary);
  font-size: 3.2rem;
  font-weight: 500;
}

h3{
  color: var(--color--primary);
  font-size: 2.4rem;
  font-weight: 700;
}

h4{
  color: var(--color--primary);
  font-size: 2.4rem;
  font-weight: 500;
}

h5{
  font-size: 2rem;
  font-weight: 500;
}

p{
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
}

.body{
  line-height: 1.29;
}

.btn{
  padding: 1rem 3.8rem;
}

.btn--body, .btn--main, .btn--outline {
  padding: 1.2rem 3.2rem;
  border-radius: 2.5rem;

  cursor: pointer;
}

.btn--main{
  background-color: var(--color--primary);
  color: var(--color--white);
  border: none;
  cursor: pointer;
}

.btn--main:hover{
  background-color: var(--color--primary__tint);
}

.btn--main:active{
  background-color: var(--color--primary);
}

.btn--body{
  border: .1rem solid  var(--color--primary__tint);
  background-color: #D8D4D5;
}

.btn--body:active{
  border-color: var(--color--primary__tint);
}

.btn--body:hover{
  border-color: var(--color--primary);
}

.btn--outline{
  border: .1rem solid  var(--color--primary__tint);
  background-color: transparent;
}

.btn--outline:active{
  border-color: var(--color--primary__tint);
}

.btn--outline:hover{
  border-color: var(--color--primary);
}

.modal{
    position: absolute;

    top: 0;
    left: 0;
    z-index: 15;

    width: 100%;
    height: 100%;

    border-radius: 2.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
}


@media (max-width: 75em) {
    html{
      font-size: 60%;
    }
}
@media (max-width: 60em) {
    html{
      font-size: 55%;
    }
}


@media (max-width: 15.625em) {
   p{
    font-size: 1.6rem;
   }
}