.section {
    padding: 8rem 10rem;
}

.section h3{
    margin-bottom: 3rem;
}

.section p, .section p strong{
    font-size: 2.4rem;
}

@media (max-width:50em) {
    .section p, .section p strong{
        font-size: 1.8rem;
    }
}

@media (max-width:37.5em) {
    .section {
        padding-left: 3.2rem;
        padding-right: 3.2rem;
    }

    .section p, .section p strong, .section h3{
        text-align: center;
    }
}


@media (max-width:21.875em) {
    .section {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}


@media (max-width:17.1875em) {
    .section p, .section p strong{
        font-size: 1.6rem;
    }
}