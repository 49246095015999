.section {
    background-color: var(--color--milk-bg);
    padding: 4rem 10.2rem 4rem 9.8rem;

    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.section1 h3, .section2 h3{
    margin-bottom: 3rem;
}

.service1, .service2{
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 8rem;
}

.service-desc p{
    max-width: 54rem;
}

.service1 img{
    border-radius: 2.5rem 2.5rem 0 2.5rem;
}

.service2 img{
    border-radius: 0 2.5rem 2.5rem 2.5rem;
}

.service-desc h2{
    margin-bottom: 2rem;
}

.service2 .service-desc{
    text-align: right;
}

.btn-more-wrapper{
    text-align: right;
}

.btn-more, .more{
    border: none;
    background-color: transparent;
    color: var(--color--primary);
    font-size: 2.4rem;
    font-weight: normal;
    cursor: pointer;
    text-decoration: none;
}

.more{
    display: inline-block;
    border-bottom: .2rem solid var(--color--primary);
}


@media (max-width: 83.69em) {
    .service1 img, .service2 img{
        width: 50rem;
    }

    .service1{
        margin-bottom: 4.8rem;
    }
}



@media (max-width: 68.75em) {
    .service1 img, .service2 img{
        width: 30rem;
    }
}


@media (max-width: 46.875em) {
    .section {
        padding-left: 2rem;
        padding-right: 2rem;
    }

}


@media (max-width: 37.5em) {
    .section h3{
        text-align: center;
    }

    .service-desc h2{
        font-size: 2rem;
    }

    .service-desc p{
        max-width: 100%;
        text-align: center;
    }

    .service-desc h2{
        text-align: center;
    }

    .service1{
        display: flex;
        flex-direction: column;
        gap: 4.8rem;
    }

    .service2{
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 4.8rem;
    }

    .service2 img{
        grid-row: 1;
        margin: 0 auto;
    }

    .btn-more, .more{
        font-size: 1.6rem;
    }

    .section {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .service-desc {
        text-align: center;
    }

    .service1 img, .service2 img{
        max-width: 90%;
        border-radius: 2.5rem;
    }
}


@media (max-width:17.1875rem) {
    .service-desc p{
        font-size: 1.6rem;   
    }
}