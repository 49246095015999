.section {
    background-color: var(--color--milk-bg);
    padding: 4rem;

    display: flex;
    flex-direction: column;
    gap: 8rem;
    align-items: center;
}

.section1 h3, .section2 h3{
    margin-bottom: 3rem;
}

.service1, .service2{
    display: flex;
    align-items: center;
    gap: 8rem;
}

.service1 img, .service2 img{
    width: 50rem;
    height: 35.2rem;
}

.service-desc p{
    max-width: 54rem;
}

.service1 img{
    border-radius: 0rem 2.5rem 2.5rem 2.5rem;
}

.service2 img{
    border-radius: 0 2.5rem 2.5rem 2.5rem;
}

.service-desc h2{
    margin-bottom: 2rem;
}

.service2 .service-desc{
    text-align: right;
}

.btn-more-wrapper{
    text-align: right;
}


@media (max-width: 62.5em) {
    .service1 img, .service2 img{
        width: 40rem;
        height: 25.2rem;
    }

    .service-desc p{
        max-width: 40rem;
    }
}


@media (max-width: 43.75em) {
    .service1 img, .service2 img{
        width: 30rem;
        height: 15rem;
    }

    .service-desc p{
        font-size: 1.8rem;
        max-width: auto;
    }

    .service-desc h2{
        font-size: 2.4rem;
    }

    .service1, .service2{
        gap: 4rem;
    }
}

@media (max-width: 34.375em) {
    .service1, .service2{
        flex-direction: column;
        justify-content: center;
    }

    .service2 .service-desc, .service-desc{
        text-align: center;
    }
}

@media (max-width: 18.75em) {
    .section{
        padding: 1rem;
    }
    .section h3{
        text-align: center;
    }

    .service1 img, .service2 img{
        width: 25rem;
        height: 15rem;
        border-radius: 1.5rem;
    }

    .service-desc p{
        font-size: 1.6rem;
    }

    .service-desc h2{
        font-size: 2rem;
    }

    .service2{
        display: grid;
        grid-template-columns: 1fr;
    }

    .service2 img{
        grid-row: 1;
        margin: 0 auto;
    }
}