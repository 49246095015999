.wrapper {
    padding: 1.2rem 18rem 2rem 18rem;
    background-color: var(--color--violet);
    color: var(--color--white);
}

.wrapper ul{
     display: flex;
    justify-content: space-between;
}

.wrapper ul li{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.metric{
    font-size: 3.2rem;
    font-weight: 500;
}

.desc {
    font-size: 2.4rem;
}

@media (max-width:66.5rem) {
    .wrapper {
        padding-left: 8rem;
        padding-right: 8rem;
    }
}


@media (max-width:50rem) {
    .metric{
        font-size: 2.4rem;
    }

    .desc {
        font-size: 1.8rem;
    }

    .wrapper ul li{
        text-align: center;
    }
}

@media (max-width:31.25rem) {
    .metric{
        font-size: 1.8rem;
    }

    .desc {
        font-size: 1.6rem;
        max-width: 12rem;
    }

    .wrapper{
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media (max-width:31.25rem) {
    .metric{
        font-size: 1.6rem;
    }

    .desc {
        font-size: 1.4rem;
        max-width: 12rem;
    }

    .wrapper{
        padding-left: 1rem;
        padding-right: 1rem;
    }
}


@media (max-width:17.1875rem) {
    .metric{
        font-size: 1.4rem;
    }

    .desc {
        font-size: 1.2rem;
    }
}